import Head from "next/head";
import Link from "next/link";
import { useRouter } from "next/router";
import ProgressBar from "../../client/components/ProgressBar";
import { useGetCurrentUserQuery } from "../../client/graphql/getCurrentUser.generated";
import { getAllModules } from "../../lib/contentful";
import daysUntilDueDate from "../../lib/days-until-duedate";
import findPregnancyWeek from "../../lib/find-pregnancy-week";
import percentIntoPregnancy from "../../lib/percent-into-pregnancy";
import checkDaysUntilDate from "../../lib/time-diff";

export default function Dashboard({ modules }: { modules: any[] }) {
  const router = useRouter();

  const [{ data, fetching, error }] = useGetCurrentUserQuery();

  const hasBeenFetchedOnce = Boolean(data || error);
  const initialFetching = fetching && !hasBeenFetchedOnce;

  if (initialFetching) return <p>Loading...</p>;
  if (error) return <p>{error.message}</p>;

  if (!data?.currentUser) {
    if (process.browser) router.push("/login");
    return (
      <p>
        Viderstiller dig til <Link href="/login">/log ind</Link>
        ...
      </p>
    );
  }

  const dueDate = data.currentUser.dueDate || undefined;
  const daysIntoPregnancy = data.currentUser.daysIntoPregnancy || undefined;

  const percentageDone = percentIntoPregnancy(data.currentUser.dueDate!);
  const nextModule = getNextModule({ modules, daysIntoPregnancy });
  const nextClosedModule = getNextClosedModule({ modules, daysIntoPregnancy });

  return (
    <>
      <Head>
        <title>{data.currentUser.name}'s Graviditetsforløb</title>
        <meta
          property="og:title"
          content="Min Mave Graviditetsforløb"
          key="title"
        />
      </Head>
      <div className="p-4 lg:p-12 flex items-start flex-col lg:flex-row justify-between space-y-8 lg:space-y-0 lg:space-x-8">
        <Section>
          <div className="text-2xl  font-sans font-semibold mb-8">
            Velkommen tilbage {data.currentUser.name}!
          </div>
          <div className="flex flex-row items-center space-x-24 justify-center font-sans font-medium text-lg mb-12">
            <div>
              <div className="text-2xl font-bold mb-1">
                Uge {findPregnancyWeek(data.currentUser.dueDate!)}
              </div>
              {data.currentUser.dateWeek}
            </div>
            <div>
              <div className="text-2xl font-bold mb-1">
                {daysUntilDueDate(data.currentUser.dueDate!)} dage
              </div>
              til termin
            </div>
          </div>
          <div>
            <div className="text-left mb-1 font-medium pl-2">
              Din baby er {percentageDone}% færdig
            </div>
            <ProgressBar progress={percentageDone} />
          </div>
        </Section>
        {nextModule && nextClosedModule && (
          <Section>
            <div className="mb-12 text-left">
              <div className="text-2xl font-sans font-semibold mb-4">
                Dit næste modul
              </div>
              <div className="text-left leading-normal">
                <div className="text-lg xl font-bold mb-2">
                  {nextModule.title}
                </div>
                <div className="text-base mb-4">{nextModule.teaser}</div>
                <div>
                  <Link href={`/app/moduler/${nextModule.slug}`}>
                    <a className="px-4 py-2 flex items-center w-max bg-mm-rose text-black rounded-full font-medium text-sm">
                      Gå til modulet
                    </a>
                  </Link>
                </div>
              </div>
            </div>

            <div className="mb-8 text-left text-gray-500">
              <div className="text-2xl font-sans font-semibold mb-4 text-gray-500">
                Du kan glæde dig til
              </div>
              <div className="text-left leading-normal">
                <div className="text-lg xl font-bold mb-2">
                  {nextClosedModule.title}
                </div>
                <div className="text-base mb-4">{nextClosedModule.teaser}</div>
                <div>
                  <div className="px-4 py-2 flex items-center w-max bg-gray-100 text-gray-500 rounded-full font-medium text-sm">
                    Du får adgang om{" "}
                    {checkDaysUntilDate(
                      dueDate!,
                      nextClosedModule.daysIntoPregnancy
                    )}{" "}
                    dage
                  </div>
                </div>
              </div>
            </div>
          </Section>
        )}
      </div>
    </>
  );
}

const Section = ({ children }: { children: any }) => (
  <div className="bg-mm-light-rose rounded-lg p-8 w-full lg:w-1/2 text-center">
    {children}
  </div>
);

const getNextModule = ({
  modules,
  daysIntoPregnancy,
}: {
  modules: any[];
  daysIntoPregnancy?: number;
}) => {
  if (!daysIntoPregnancy) return;

  const modulesWithAcess = modules.filter(
    (m) => m.daysIntoPregnancy <= daysIntoPregnancy
  );
  const nextModule = modulesWithAcess[modulesWithAcess.length - 1];
  return nextModule;
};

const getNextClosedModule = ({
  modules,
  daysIntoPregnancy,
}: {
  modules: any[];
  daysIntoPregnancy?: number;
}) => {
  if (!daysIntoPregnancy) return;

  const modulesWithoutAcess = modules.filter(
    (m) => m.daysIntoPregnancy > daysIntoPregnancy
  );
  const nextClosedModule = modulesWithoutAcess[0];
  return nextClosedModule;
};

export async function getStaticProps() {
  const modules = await getAllModules();

  return {
    props: {
      modules,
    },
  };
}
