import daysUntilDueDate from "./days-until-duedate";

export default function findPregnancyWeek(dueDate: string) {
  const daysLeftInPregnancy = daysUntilDueDate(dueDate);
  const daysIntoPregnancy = 280 - daysLeftInPregnancy;

  const weekNumber = Math.floor(daysIntoPregnancy / 7) + 1;

  return weekNumber;
}
