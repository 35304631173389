export default function ProgressBar({ progress }: { progress: number }) {
  return (
    <div className="w-full bg-gray-100 rounded-full">
      <div
        className="bg-mm-purple text-sm font-bold text-white text-center px-0.5 py-2 leading-none rounded-full"
        style={{ width: `${progress}%` }}
      >
        {" "}
        {progress}%
      </div>
    </div>
  );
}
