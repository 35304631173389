export default function daysUntilDueDate(dueDate: string) {
  // One day in milliseconds
  const oneDay = 1000 * 60 * 60 * 24;

  // Calculating the time difference between today and due date
  const jsDueDate = new Date(dueDate);
  const today = new Date();
  const diffInTime = jsDueDate.getTime() - today.getTime();

  // Calculating the no. of days until the due date
  const daysUntilDueDate = Math.round(diffInTime / oneDay) + 1;

  return daysUntilDueDate;
}
