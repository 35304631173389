import daysUntilDueDate from "./days-until-duedate";

export default function percentIntoPregnancy(dueDate: string) {
  const daysLeftInPregnancy = daysUntilDueDate(dueDate);

  const percentIntoPregnancy = Math.round(
    100 - (daysLeftInPregnancy / 280) * 100
  );

  return percentIntoPregnancy;
}
